exports.components = {
  "component---src-layouts-article-layout-tsx-content-file-path-src-content-articles-building-websites-and-rest-ap-is-using-native-golang-mdx": () => import("./../../../src/layouts/articleLayout.tsx?__contentFilePath=/home/runner/work/fullstackrocks/fullstackrocks/src/content/articles/Building-websites-and-REST-APIs-using-native-Golang.mdx" /* webpackChunkName: "component---src-layouts-article-layout-tsx-content-file-path-src-content-articles-building-websites-and-rest-ap-is-using-native-golang-mdx" */),
  "component---src-layouts-article-layout-tsx-content-file-path-src-content-articles-drag-and-drop-in-react-and-redux-mdx": () => import("./../../../src/layouts/articleLayout.tsx?__contentFilePath=/home/runner/work/fullstackrocks/fullstackrocks/src/content/articles/drag-and-drop-in-react-and-redux.mdx" /* webpackChunkName: "component---src-layouts-article-layout-tsx-content-file-path-src-content-articles-drag-and-drop-in-react-and-redux-mdx" */),
  "component---src-layouts-article-layout-tsx-content-file-path-src-content-articles-learning-to-program-if-you-already-know-how-to-code-learning-to-code-if-you-already-know-how-to-code-mdx": () => import("./../../../src/layouts/articleLayout.tsx?__contentFilePath=/home/runner/work/fullstackrocks/fullstackrocks/src/content/articles/learning-to-program-if-you-already-know-how-to-code/Learning-to-code-if-you-already-know-how-to-code.mdx" /* webpackChunkName: "component---src-layouts-article-layout-tsx-content-file-path-src-content-articles-learning-to-program-if-you-already-know-how-to-code-learning-to-code-if-you-already-know-how-to-code-mdx" */),
  "component---src-layouts-article-layout-tsx-content-file-path-src-content-articles-wordpress-plugins-any-lang-mdx": () => import("./../../../src/layouts/articleLayout.tsx?__contentFilePath=/home/runner/work/fullstackrocks/fullstackrocks/src/content/articles/wordpress-plugins-any-lang.mdx" /* webpackChunkName: "component---src-layouts-article-layout-tsx-content-file-path-src-content-articles-wordpress-plugins-any-lang-mdx" */),
  "component---src-layouts-tutorial-layout-tsx-content-file-path-tutorials-redis-basics": () => import("./../../../src/layouts/tutorialLayout.tsx?__contentFilePath=/tutorials/redis-basics" /* webpackChunkName: "component---src-layouts-tutorial-layout-tsx-content-file-path-tutorials-redis-basics" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-article-tsx": () => import("./../../../src/pages/article.tsx" /* webpackChunkName: "component---src-pages-article-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

